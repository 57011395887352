import { useFeatureFlag } from './useFeatureFlag';

interface UseSunsetOldFormsPortalFeatureFlagResults {
  isOldPortalAccessRestricted: boolean;
  isCheckingOldPortalAccessRestriction: boolean;
}

export const useSunsetOldFormsPortalFeatureFlag =
  (): UseSunsetOldFormsPortalFeatureFlagResults => {
    const { canUseFeature: isOldPortalAccessRestricted, isCheckingFeatureFlag } =
      useFeatureFlag('sunset-old-forms-portal');

    return {
      isOldPortalAccessRestricted: isOldPortalAccessRestricted && !isCheckingFeatureFlag,
      isCheckingOldPortalAccessRestriction: isCheckingFeatureFlag,
    };
  };
