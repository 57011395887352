import { APP_ENVIRONMENT } from 'shared/constants';

export function redirectTo(url: string) {
  window.open(url, '_blank', 'noopener');
}

export function getRedirectionBaseURL() {
  let baseUrl = 'https://app.getweave.com';

  if (APP_ENVIRONMENT === 'development') {
    baseUrl = 'https://app.weavedev.net/';
  }

  return baseUrl;
}

export function redirectToNewFormBuilder(formId = 'new') {
  redirectTo(`${getRedirectionBaseURL()}/forms/builder/${formId}`);
}

export function redirectToNewPacketsBuilder(packetId = 'new') {
  redirectTo(
    `${getRedirectionBaseURL()}/forms/library?list=packets&documentId=${packetId}`
  );
}
