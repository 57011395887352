import { useQuery } from 'react-query';

import { CustomAxios } from 'shared/helpers/axios';
import { queryKeys, APP_BASE_URL } from 'shared/constants';

export function useFeatureFlag(flag: string) {
  const { data, isLoading } = useQuery(
    [queryKeys.featureFlag, flag],
    () => fetchFeatureFlag(flag),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
    }
  );

  return {
    canUseFeature: data || false,
    isCheckingFeatureFlag: isLoading,
  };
}

interface FeatureFlagResponse {
  data: boolean;
}

async function fetchFeatureFlag(flag: string) {
  const respose = await CustomAxios.get<FeatureFlagResponse>(
    `/featureflags/${flag}/enabled`,
    {
      baseURL: APP_BASE_URL,
    }
  );

  return respose.data.data;
}
