import { useEffect, useRef, useState } from 'react';

import { FormComponentsProvider, useWritebackCapabilitiesContext } from 'context';
import { useMedicalConditions } from 'shared/hooks';
import { IFormBuilder as FB } from 'shared/types';

import { useBuilderElements } from '../../../../hooks';
import { formEditorRootStyles } from './form-builder-core.style';

export const FormEditorCore = () => {
  const { data: builderElementsData, isSuccess: areBuilderFieldsLoaded } =
    useBuilderElements();

  const {
    preferredSourceTenantId,
    getSourceCapabilities,
    isFetchingWritebackSourcesCapabilities,
    isLoadingPreferredSourceTenant,
    getSourceId,
  } = useWritebackCapabilitiesContext();

  const [isBuilderReady, setIsBuilderReady] = useState(false);
  const builderSources = useRef<FB.BuilderSources | undefined>();
  const sectionTemplates = useRef<FB.ISectionTemplates | undefined>();

  const preferredSourceId = getSourceId(preferredSourceTenantId);

  const {
    data: medicalConditions,
    isLoading: optionsSetLoading,
    isEmpty: isOptionSetEmpty,
    isError: optionSetsLoadingError,
  } = useMedicalConditions({
    sourceTenantId: preferredSourceTenantId,
    hasMedicalConditons:
      getSourceCapabilities(preferredSourceId).showMedicalConditionsTab,
  });

  const capabilities = getSourceCapabilities(preferredSourceId);

  useEffect(() => {
    // wait until we have the data we need for the builder
    if (
      areBuilderFieldsLoaded &&
      builderElementsData &&
      !optionsSetLoading &&
      !isLoadingPreferredSourceTenant &&
      !isFetchingWritebackSourcesCapabilities
    ) {
      const primaryFields = JSON.parse(builderElementsData.primaryFields);
      const elements = JSON.parse(builderElementsData.elements);
      const sectionTemplatesData = JSON.parse(builderElementsData.sectionTemplates);
      const fields = JSON.parse(builderElementsData.fields);

      if (capabilities.showMedicalConditionPrimaryFields) {
        primaryFields.allergies.meta.isEmpty =
          medicalConditions[0]?.optionSet?.isEmpty || isOptionSetEmpty;

        primaryFields.disease.meta.isEmpty =
          medicalConditions[1]?.optionSet?.isEmpty || isOptionSetEmpty;

        primaryFields.medications.meta.isEmpty =
          medicalConditions[2]?.optionSet?.isEmpty || isOptionSetEmpty;

        primaryFields.allergies.meta.isError = optionSetsLoadingError;
        primaryFields.disease.meta.isError = optionSetsLoadingError;
        primaryFields.medications.meta.isError = optionSetsLoadingError;
      } else {
        delete primaryFields.allergies;
        delete primaryFields.disease;
        delete primaryFields.medications;
      }

      let sources: FB.BuilderSources = {
        primaryFields,
        elements: { ...fields, ...elements },
      };

      builderSources.current = sources;
      sectionTemplates.current = sectionTemplatesData;

      setIsBuilderReady(true);
    }
  }, [
    builderElementsData,
    optionsSetLoading,
    areBuilderFieldsLoaded,
    isLoadingPreferredSourceTenant,
    isFetchingWritebackSourcesCapabilities,
  ]);

  return (
    <div css={formEditorRootStyles}>
      <FormComponentsProvider
        isBuilderReady={isBuilderReady}
        sectionTemplates={sectionTemplates.current}
        sources={builderSources.current}
      ></FormComponentsProvider>
    </div>
  );
};
